<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								Tambah User
							</CCol>
							<CCol md="4" class="text-right">
								
							</CCol>
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Nip <span class="text-danger">*</span></b></label>
								<div class="input-group mb-3">
									<input type="text" class="form-control" id="nip">
									<div class="input-group-append">
										<span class="input-group-text" id="basic-addon2" v-on:click="chekNIPPegawai"><i class="fa fa-search"></i></span>
									</div>
								</div>
							</div>
							<div class="form-group">
								<label for=""><b>Nama <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" id="name" v-model="nama_pegawai">
							</div>
							<div class="form-group">
								<label for=""><b>Email <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" id="email" v-model="email_pegawai">
							</div>
							<div class="form-group">
								<label for=""><b>Phone <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" id="phone" v-model="nohp_pegawai">
							</div>
							<div class="form-group d-none">
								<label for=""><b>NPWP <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" id="npwp">
							</div>
							<div class="form-group">
								<label for=""><b>Jabatan <span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" id="jabatan" v-model="jabatan">
							</div>
							<div class="form-group">
								<label for=""><b>Role <span class="text-danger">*</span></b></label>
								<v-select :options="list_role" :reduce="name => name.id" label="name" v-model="roles_id"></v-select>
								<!-- <input type="text" class="form-control" id="n_nama"> -->
							</div>
							<div class="form-group ">
								<label for=""><b>Password <span class="text-danger">*</span></b></label>
								<input type="password" class="form-control" id="password">
							</div>
							<div class="form-group ">
								<label for=""><b>Konfirmasi Password <span class="text-danger">*</span></b></label>
								<input type="password" class="form-control" id="password_confirmation">
							</div>
						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
const nullarray = [];
	export default {
		name: "AddUser",
		data(){
			return{
				list_role: nullarray,
				roles_id: '',
				nama_pegawai: '',
				email_pegawai: '',
				nohp_pegawai: '',
				jabatan: '',
				
			}
		},
		methods: {
			chekNIPPegawai: function () {
				Swal.fire({
						title: '<i class="fa fa-refresh fa-spin"></i>',
						text: "Loading...",
						allowOutsideClick: false,
						showConfirmButton: false,
					});
				axios
                .get(this.apiLink + "api/pegawai", {
                	params: {
					    nip: $('#nip').val(),
					},
                    headers: {
                        Authorization: "Bearer " + this.access_token,
                    },
                })
                .then((response) => {
                    var res_pegawai = response.data;
                    var res_pegawai_data = res_pegawai.data.master;
                    let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: "NIP Pegawai ditemukan.",
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						allowOutsideClick: false,
						didOpen: () => {
							timerInterval = setInterval(() => {
								const content = Swal.getContent();
								if (content) {
									const b = content.querySelector("b");
									if (b) {
										b.textContent = Swal.getTimerLeft();
									}
								}
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
							this.nama_pegawai = res_pegawai_data.p_nama_lengkap;
							this.email_pegawai = res_pegawai_data.p_email;
							this.nohp_pegawai = res_pegawai_data.p_nohp;
						}
					});
                })
                .catch((error) => {
				  	var error_res = error.response.data.meta;
				  	if (error_res.code != 200) {
					  	Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: error_res.message
						});
				  	}
				});
			},
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
				});
				var FormData = require('form-data');
				var data = new FormData();
				data.append('nip',$('#nip').val());
				data.append('name',$('#name').val());
				data.append('email',$('#email').val());
				data.append('phone',$('#phone').val());
				data.append('npwp',$('#npwp').val());
				data.append('jabatan',$('#jabatan').val());
				data.append('roles_id', this.roles_id);
				data.append('nib','');
				data.append('tipe_pemohon_id','');
				data.append('password',$('#password').val());
				data.append('password_confirmation',$('#password_confirmation').val());
				var config = {
				  method: 'post',
				  url: this.apiLink+'api/crud/user',
				  headers: { 
				    'Authorization': 'Bearer '+this.access_token, 
				  },
				  data : data
				};
				axios(config)
				.then(function (response) {
					var res_data = response.data;
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_user.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master user segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								if (self.roles_id == 2) {
									self.$router.push({name: 'AllUser', params:{role: 'pemohon'}});
								}
								else{
									self.$router.push({name: 'AllUser', params:{role: 'admin'}});
								}
								
							}
						}).catch((error) => {
						  	var error_res = error.response.data.meta;
						  	if (error_res.code != 200) {
							  	Swal.fire({
									icon: 'error',
									title: 'Oops...',
									text: error_res.message
								});
						  	}
						});
					}
				});
			},
		},
		created() {
			// Get Master Negara
			axios.get(this.apiLink + "api/master/role", {
                params: {
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
			.then((response) => {
				var res_role = response.data;
				var res_role_data = res_role.data;
				if (res_role.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_role.data.message
					});
				}
				else{
					this.list_role = res_role_data.master;
				}
			});
		}
	};
</script>